import { useState, useEffect, useMemo } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { Container, Row, Card, Button, Col, InputGroup, FormControl, CardGroup, Modal, ButtonGroup, CardDeck, Image, Carousel, CarouselItem, ModalDialog, ModalBody} from 'react-bootstrap';
import Navigation from './Navigation';
import Footer from './Footer';
import tasteMakersLogo from './Tastemakers Basic Logo.png'
import crown from './crown.png';
import { useNavigate } from 'react-router-dom';

import { App } from '@capacitor/app';

export default function UserProfile() {
    // Get the current URL
    const location = useLocation();
    const {userId} = location.state;

    const userEmail = sessionStorage.getItem("email")
    const [backendUser, setBackendUser] = useState([]);
    const [spotifyUser, setSpotifyUser] = useState([]);
    const [posts,setPosts] = useState([]);
    const [followerIds,setFollowersIds] = useState([]);
    const [followingIds,setFollowingIds] = useState([]);
    const [following, setFollowing] = useState([]);
    const [followers, setFollowers] = useState([]);
    const followingCount = useMemo(() => following.length, [following]);
    const followersCount = useMemo(() => followers.length, [followers]);
    const [isLiked, setIsLiked] = useState(false);
    const [likes, setLikes] = useState(0);
    const [userPro, setUserPro] = useState(false);
    const [userImg, setUserImg] = useState('');
    const [isFollowing, setIsFollowing] = useState(false);
    const [profileUserId, setProfileUserId] = useState('');
    const [userTitle, setUserTitle] = useState('');
    const [privacyBool, setPrivacyBool] = useState(false)
    const [notificationId, setNotificationId] = useState('');
    const navigate = useNavigate();

    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
      }, []);

      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  
      useEffect(() => {
  
        const handleResize = () => {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        };
      
        window.addEventListener("resize", handleResize);
        handleResize(); // Set initial size on mount
      
        return () => window.removeEventListener("resize", handleResize);
        
      }, []);
      useEffect(() => {
        const backButtonListener = App.addListener('backButton', (data) => {
            if (window.location.pathname === '/') {
                App.exitApp(); // Exit app if on the home page
            }else {
                  navigate(-1); // Go to the previous page
              } 
        });

        // Cleanup the listener when the component unmounts
        return () => {
            backButtonListener.remove();
        };
    }, [navigate]);

      useEffect(() => {
        async function fetchData() {
          try {
            // Fetching register user data
            const registerResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/register/${userEmail}`);
            if (!registerResponse.ok) {
              throw new Error('Failed to fetch register user');
            }
            const registerData = await registerResponse.json();
            setSpotifyUser(registerData);
      
            // Fetching Spotify user data
            const spotifyResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/users/' + '\"' + registerData.userName + '\"', {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                'Content-Type': 'application/json',
              },
            });
      
            if (!spotifyResponse.ok) {
              throw new Error('Failed to fetch Spotify user');
            }
            const spotifyUserData = await spotifyResponse.json();
            setBackendUser(spotifyUserData);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        }
      
        fetchData();
      }, []);
      
      useEffect(() => {
        if (!backendUser || !backendUser.userId) return;
      
        async function runUser() {
          try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/${userId}`,{
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                'Content-Type': 'application/json',
              },
            });
            const data = await response.json();
            setUserPro(data.paidMember);
            setFollowing(data.following);
            setFollowers(data.followers);
            setUserImg(data.profileImageUrl);
            setProfileUserId(data._id);
            setUserTitle(data.userTitle);
            setPrivacyBool(data.privateProfile);
            setNotificationId(data.notificationId);
          } catch (error) {
            console.error('Error:', error);
          }
        }
      
        runUser();
      }, [backendUser]);
      
      useEffect(() => {
        if (!backendUser || !backendUser.userId) return;
      
        async function getUserPosts() {
          try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/posts/getAllUserPosts/${userId}`, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                'Content-Type': 'application/json',
              },
            });
            const data = await response.json();
            setPosts(data);
          } catch (error) {
            console.error('Error fetching user posts:', error);
          }
        }
      
        getUserPosts();
      }, [backendUser, likes, isLiked]);
      
      useEffect(() => {
        if (!backendUser || !backendUser.userId) return;
      
        const fetchDetails = async (userIds) => {
          return Promise.all(
            userIds.map(async (userId) => {
              try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/getUserById/${userId}`, {
                  method: 'GET',
                  headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                  },
                });
                const data = await response.json();
                return {
                  userId: data.userId, 
                  following: data.following, 
                  followers: data.followers, 
                  userTitle: data.userTitle,
                  profileImageUrl: data.profileImageUrl
                  // other details if needed
                };
              } catch (error) {
                console.error('Error fetching user details:', error);
                return null;
              }
            })
          );
        };
      
        const updateDetails = async () => {
          if (followers.length > 0) {
            const followerDetails = await fetchDetails(followers);
            setFollowersIds(followerDetails.filter(detail => detail !== null));
          }
      
          if (following.length > 0) {
            const followingDetails = await fetchDetails(following);
            setFollowingIds(followingDetails.filter(detail => detail !== null));
          }
        };
      
        updateDetails();
      }, [backendUser, followers, following, isFollowing]);
      
      useEffect(() => {
        //console.log('Followers:', followers);
    
        if (!Array.isArray(followers) || followers.length === 0) {
            //console.log('No followers or invalid data structure');
            return;
        }
    
        if (!backendUser || !backendUser._id) {
            //console.log('Backend user data not loaded or invalid');
            return;
        }
    
        followers.forEach(follower => {
            if (follower && follower._id) {
                //console.log("Array Id:" + follower._id);
            }
        });
    
        const followingStatus = followers.some(follower => follower === backendUser._id);
        //console.log(backendUser._id)
        //console.log('Following Status:', followingStatus);
        setIsFollowing(followingStatus);
    
    }, [followers, backendUser]); // Make sure backendUser is also a dependency
    
    useEffect(() => {

    },[followersCount, isFollowing])

      function handleCardPress(postId,userId){
        navigate('/User/' + userId + '/Post/' + postId, {
          state: {userId, postId}
      })
    }

    // frontend/src/yourComponent.js

async function handleFollowNotification(userId, followerName) {
    alert("onesignal code reached");
    alert("ID: " + process.env.REACT_APP_ONE_SIGNAL_APP_ID); // This can be removed as it's now handled by the backend
    alert("Key: " + process.env.REACT_APP_ONE_SIGNAL_API_KEY); // Remove this too for security

    if (!userId) {
        alert("No device ID found for user.");
        return;
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/send-notification`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // No need to send Authorization here as the backend handles it
            },
            body: JSON.stringify({ userId, followerName }),
        });

        if (response.ok) {
            const result = await response.json();
            alert('Notification sent successfully: ' + JSON.stringify(result));
            navigate(0)
        } else {
            const errorData = await response.json();
            alert('Failed to send notification: ' + errorData.error + ' - ' + (errorData.details || ''));
        }
    } catch (error) {
        alert('Error sending notification: ' + error.message);
    }
}

    
    

    function privateProfileHandle() {
      // console.log(followerIds);
  
      // Extract userIds from the followerIds array
      const followerUserIds = followerIds.map(follower => follower.userId);
  
      // console.log(followerUserIds);
      if (followerUserIds.includes(backendUser.userId) || privacyBool == false) {
          return(
<Carousel defaultActiveIndex={1} interval={null} style={{width: '100%'}}>
        <CarouselItem interval={null}>
        <style>
        {`
          .carousel-control-prev,
          .carousel-control-next {
            display: none;
          }
        `}
      </style>
            <div style={{height: '85vh', color: 'white'}}>
                <h1 style={{textAlign: 'center', color: 'white', marginBottom: '2rem'}}>Following</h1>
                <Col style={{height: '100%', display: 'flex', flexDirection: 'column', alignItems:'center'}}>
                    <Row style={{width: '100%', display: 'flex', justifyContent: 'center', overflowY: 'auto'}}>
                           {followingIds != null &&
                                followingIds.map((follower) => {
                                    return(
                                      <Card key={follower._id} style={{ borderColor:'orange',display: 'inline-block', alignItems: 'center', justifyContent: 'center', justifyItems: 'center',width: isMobile ? '90vw' : '60%', height: '6rem', backgroundColor: 'black', color: 'white', marginBottom:'1rem' }}>

                                      <CardGroup style={{display:'flex'}}>
                                        <Card.Img src={follower.profileImageUrl || tasteMakersLogo} style={{ borderRadius: '50%',marginTop:'.5rem',width: '5rem', height: '5rem' }} />
                                         <Card style={{backgroundColor:'black', color:'white'}}>
                                         <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                          <div>
                                          <Card.Text style={{ marginLeft: '2rem', marginTop: '1rem', marginBottom:'0rem' }}>@{follower.userId.replace(/^"|"$/g, '')}</Card.Text>
                                          <Card.Text style={{ marginLeft:'2rem'
                                        }}>
                                        <span style={{color:'white'}}>Muxer </span><span style={{  color:
                                            follower.userTitle === 'Basic' ? 'black' :
                                            follower.userTitle === 'Seer' ? 'cyan' :
                                            follower.userTitle === 'Certified' ? 'gold' :
                                            follower.userTitle === 'Pro' ? 'orange' :
                                            follower.userTitle === 'Prime'? 'red':
                                            'black'
                                         }}>{follower.userTitle}</span>
                                        </Card.Text>
                                          </div>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1.5rem', marginLeft:'1rem' }}>

                                          <Button style={{ width: '5rem', height: '2.5rem', color:"orange",backgroundColor:"black",borderColor:"orange", marginLeft:'4rem'}}>Report</Button>
                                        </div>
                                       </div>
                                       </Card>
                                      </CardGroup>
                                      
                                    </Card>

                                )})}
                           </Row>
                            </Col>
                    </div>
                    </CarouselItem>
                    <CarouselItem>
    <div style={{ height: '85vh', width: '100%', position: 'relative' }}>
        <h1 style={{
            position: 'sticky',
            top: '0',
            left: '0',
            width: '100%',
            textAlign: 'center',
            margin: '0',
            padding: '1rem 0',
            backgroundColor: 'black',
            color: 'white',
            zIndex: '1000',
            borderBottom: '2px solid orange'
        }}>
            Posts
        </h1>
        <div style={{
            paddingTop: '4rem',
            height: 'calc(85vh - 4rem)',
            overflowY: 'auto',
            backgroundColor: 'black',
            color: 'white'
        }}>
            <Col style={{ display: 'flex', justifyContent: 'center' }}>
                <Row className="flex" style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    scrollbarColor: 'orange black',
                    scrollbarWidth: 'thin',
                    WebkitOverflowScrolling: 'touch',
                    width: '100%',
                }}>
                    {posts.length != null && posts.slice().reverse().map(post => (
                        <Card key={post._id} style={{
                            outlineColor: 'orange',
                            borderColor: 'orange',
                            display: 'inline-block',
                            width: '100%',
                            height: '14rem',
                            backgroundColor: 'black',
                            color: 'white',
                            marginBottom: '1rem'
                        }} onClick={() => handleCardPress(post._id, post.ownerId)}>
                            <CardGroup style={{ display: 'flex' }}>
                                <Card.Img src={post.profileImageUrl || tasteMakersLogo} style={{
                                    borderRadius: '50%',
                                    marginTop: '1rem',
                                    width: '5rem',
                                    height: '5rem'
                                }} />
                                <Card style={{ backgroundColor: 'black', color: 'white' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div>
                                            <Card.Title style={{ marginLeft: '1rem', marginTop: '2rem', marginBottom: '0rem' }}>
                                                @{post.userId.replace(/^"|"$/g, '')}
                                            </Card.Title>
                                            <Card.Text style={{ marginLeft: '1rem' }}>
                                                <span style={{ color: 'white' }}>Muxer </span>
                                                <span style={{
                                                    color:
                                                        post.userTitle === 'Basic' ? 'black' :
                                                            post.userTitle === 'Seer' ? 'cyan' :
                                                                post.userTitle === 'Certified' ? 'gold' :
                                                                    post.userTitle === 'Pro' ? 'orange' :
                                                                        post.userTitle === 'Prime' ? 'red' :
                                                                            'black'
                                                }}>{post.userTitle}</span>
                                            </Card.Text>
                                        </div>
                                    </div>
                                </Card>
                            </CardGroup>
                            <Card style={{ color: 'white', backgroundColor: 'black' }}>
                                <Card.Body>{post.message}</Card.Body>
                                <Card.Text style={{ color: 'white', justifyContent: "right", display: 'flex' }}>
                                    <Button style={{
                                        color: "orange",
                                        backgroundColor: "black",
                                        borderColor: "orange",
                                        marginRight: '1rem'
                                    }} onClick={(e) => { e.stopPropagation(); likeOrUnlikePost(post._id, backendUser.userId) }}>
                                        {post.likes.length} Likes
                                    </Button>
                                    <Button style={{
                                        color: "orange",
                                        backgroundColor: "black",
                                        borderColor: "orange"
                                    }}>
                                        {post.comments.length} Comments
                                    </Button>
                                </Card.Text>
                            </Card>
                        </Card>
                    ))}
                </Row>
            </Col>
        </div>
    </div>
</CarouselItem>


                    <CarouselItem>
    <div style={{height: '85vh', color: 'white', marginBottom:'8rem'}}>
        <h1 style={{textAlign: 'center', color: 'white', marginBottom: '0rem'}}>Followers</h1>
        <Col style={{margin: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%'}}>
            <Row className="flex" style={{scrollbarColor: 'orange black', scrollbarWidth: 'thin', WebkitOverflowScrolling: 'touch', height: '85vh', width: '100%', overflowX: 'hidden', overflowY: 'auto', marginTop: '1rem', display: 'flex', justifyContent: 'center'}}>
                           {followerIds != null &&
                                followerIds.map((follower) => {
                                    return(
                                      <Card key={follower._id} style={{ marginBottom:'1rem',borderColor:'orange',display: 'inline-block', width: isMobile ? '100%' : '60%', height: '7rem', backgroundColor: 'black', color: 'white' }}>
                                      <CardGroup style={{display:'flex'}}>
                                        <Card.Img src={follower.profileImageUrl || tasteMakersLogo} style={{borderRadius: '50%', marginTop:'.5rem', width: '5rem', height: '5rem' }} />
                                         <Card style={{backgroundColor:'black', color:'white'}}>
                                         <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                          <div>
                                          <Card.Text style={{ marginLeft: '2rem', marginTop: '1rem', marginBottom:'0rem' }}>@{follower.userId.replace(/^"|"$/g, '')}</Card.Text>
                                          <Card.Text style={{ marginLeft:'2rem'
                                        }}>
                                        <span style={{color:'white'}}>Muxer </span><span style={{  color:
                                            follower.userTitle === 'Basic' ? 'black' :
                                            follower.userTitle === 'Seer' ? 'cyan' :
                                            follower.userTitle === 'Certified' ? 'gold' :
                                            follower.userTitle === 'Pro' ? 'orange' :
                                            follower.userTitle === 'Prime'? 'red':
                                            'black'
                                         }}>{follower.userTitle}</span>
                                        </Card.Text>
                                          </div>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1.5rem' }}>

                                          <Button style={{ marginLeft:'4rem',width: '7rem', height: '2.5rem' ,color:"orange",backgroundColor:"black",borderColor:"orange"}}>Report</Button>
                                        </div>
                                       </div>
                                       </Card>
                                      </CardGroup>
                                      
                                    </Card>
                                )})}
                           </Row>
                            </Col>
                    </div>
                    </CarouselItem>
                    </Carousel>
          )
      } else {
        return(
        <div style={{color:'white'}}>
          <h1 style={{color:'white'}}>Private Profile</h1>
          <h2> Request to Follow this User to See Content</h2>
        </div>)
      }
  }  

    const likeOrUnlikePost = async (postId, userId) => {
        try {
            // Fetch user details to get realId
            const userRealIdResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/users/' + userId ,{
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                'Content-Type': 'application/json',
              },
            });
            const userData = await userRealIdResponse.json();

            if (!userData || !userData._id) {
                console.error('User data or user ID not found');
                return;
            }

            const realId = userData._id;
            //console.log('Real ID:', realId);

            // Fetch post details to check if realId is in the likePost array
            const postResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/posts/getPost/' + postId,{
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                'Content-Type': 'application/json',
              },
            });
            const postData = await postResponse.json();

            const likePostArray = postData.likes || [];
            const isLiked = likePostArray.some(like => like.userId === realId);

            if (isLiked) {
                // If already liked, unlike the post
                const unlikeResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/posts/unLikePost/' + postId + '/user/' + realId, {
                  method: 'DELETE',
                  headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                  },
                });
                const unlikeResult = await unlikeResponse.json();
                //console.log(unlikeResult);
            } else {
                // If not liked, like the post
                const likeResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/posts/likePost/' + postId + '/user/' + realId, {
                  method: 'POST',
                  headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                  },
                });
                const likeResult = await likeResponse.json();
                //console.log(likeResult);
            }

            // Update the state to trigger a re-render
            setIsLiked((prevIsLiked) => !prevIsLiked);

            setLikes(likePostArray.length);
        } catch (error) {
            // Handle errors
            // console.error('Error:', error);
        }
    };

    const handleFollowButton = async () => {
        alert("test block");
        
        try {
            let response;
    
            if (isFollowing) { 
                // Unfollow user logic
                response = await unfollowUser(backendUser._id, profileUserId, {
                    method: 'PATCH',
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                        'Content-Type': 'application/json',
                    },
                });
            } else {
                // Follow user logic
                response = await followUser(backendUser._id, profileUserId, {
                    method: 'PATCH',
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                        'Content-Type': 'application/json',
                    },
                })
                .then(res => res.json())  // Parse JSON from the response
                .then(data => {
                    alert(JSON.stringify(data));  // Alert the JSON response data
                    alert("Sending follow notification");
                    handleFollowNotification(notificationId, data.userId);
                });
            }
    
            // Check if the response is successful (HTTP status 200)
            if (response.status === 200) {
                // Update the state only on successful response
                alert("Following");
                setIsFollowing(!isFollowing);
    
                // Trigger notification if user is followed
                // if (!isFollowing) {
                //     alert("Sending follow notification");
                //     handleFollowNotification(notificationId, userId);
                // }
            } else {
                throw new Error('Failed to update follow status');
            }
    
        } catch (error) {
            console.error('Error updating follow status:', error);
        }
    
        // Refresh or redirect logic
        navigate(0);
    };    
  
  const unfollowUser = async (userId, friendId) => {
    try {
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/users/friends/deleteFollow', {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId, friendId })
        });

        //console.log("Unfollow response:", await response.json()); // Log the response body
        return response;
    } catch (error) {
        console.error('Unfollow error:', error);
        throw error; // Rethrow to handle it in the calling function
    }
};

function isTheUser (){
  if(userId == backendUser.userId){
    navigate('/Account');
  }
}

const followUser = async (userId, friendId) => {
    try {
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/users/friends/addFollow', {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId, friendId })
        });

        //console.log("Follow response:", await response.json()); // Log the response body
        return response;
    } catch (error) {
        console.error('Follow error:', error);
        throw error; // Rethrow to handle it in the calling function
    }
};
  

    function handleProUser(){
        if(userPro == true){
          return(
            <>
            <h1 style={{textAlign:'center'}}> <img alt='' style={{width:'2.3rem',height:'2.3rem', marginRight:'.5rem'}}src={crown}/>{userId.replace(/^"|"$/g, '')}</h1>
            </>
          )
        }else{
            return(
                <h1>{userId.replace(/^"|"$/g, '')}</h1>
                )
        }
      }

    const isMobile = windowSize.width < 765;

    return( 
        <div>
            <Navigation/>
            <div style={{marginTop:'1rem',height:'100%', width:'100%', backgroundColor:'black', display: isMobile ? 'block' : 'flex', color:'white', alignContent:'center',justifyContent: "center", alignItems:'center'}}>
                <div style={{ height:'70vh',alignContent:'center',justifyContent: "center", alignItems:'center' , textAlign: "center", marginTop: '2rem', width:isMobile ? '100%' : '50%' }}>
                    <div style={{width:"100%"}}>  
                    <div style={{height: isMobile ? '100vh' : '80vh', width: '100%', backgroundColor: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}>
    <div style={{textAlign: 'center', width: isMobile ? '100%' : '50%'}}>
        <img src={userImg || tasteMakersLogo} alt='...' style={{
          borderColor:'orange', // Border color
          borderWidth: '2px',    // Border width
          borderStyle: 'solid',  // Border style
          width: '20rem', borderRadius: '10rem', height: '20rem', margin: 'auto'}}/>
                     {handleProUser()}
                     {isTheUser()}
                     <h2 style={{ fontSize:'1.5rem'// default color if none of the conditions are met
                        }}>
                        <span style={{color:'white'}}>Muxer </span><span style={{  color:
                            userTitle === 'Seer' ? 'blue' :
                            userTitle === 'Certified' ? 'gold' :
                            userTitle === 'Pro' ? 'orange' :
                            userTitle === 'Prime'? 'red':
                            'white'
                            }}>{userTitle}</span>
                        </h2>
                     <div style={{ display: "flex", justifyContent: "center", marginTop:'2rem' }}>
                        <div style={{ margin: "0 1rem" }}>
                        <h5>Following</h5>
                        <h5>{followingCount}</h5>
                        </div>
                        <div style={{ margin: "0 1rem" }}>
                        <h5>Followers</h5>
                        <h5>{followersCount}</h5>
                        </div>
                    </div>
                    <div style={{textAlign:"center", marginTop:"1rem"}}>
                                <Button style={{color:'orange', backgroundColor:'black', borderColor:'orange'}}onClick={() => handleFollowButton()}>
                                {isFollowing ? 'Following' : 'Follow'}
                                </Button>
                            </div>
                </div>
                            
                        </div>
                    </div> 
                </div>
                <div style={{width: isMobile ? '100%' : '50%', display: 'flex', justifyContent: 'center'}}>
                {privateProfileHandle()}
                </div>
            </div>
            <Footer/>
        </div>
    );
}
