import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useState, useEffect } from 'react';
import companyImg from '../src/Tastemakers Basic Logo.png'
import crown from './crown.png';
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';
import { useUser } from './UserContext';
import tasteMakersLogo from './Tastemakers Basic Logo.png'
import { useNavigate, useLocation, Link} from 'react-router-dom';

const CLIENT_ID = "46a1cee5d9084a10876b12abb9c51208";
const CLIENT_SECRET = "af917974b69544beb3c66ec1045f1f73";

function Navigation() {
  // const userAuthToken = sessionStorage.getItem("token")
  const userId = sessionStorage.getItem("userId");
  const userImg = sessionStorage.getItem("imgURL");
  const userEmail = sessionStorage.getItem("email")
  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useState("");
  const [userPro, setUserPro] = useState(false);
  const [backendUser, setBackendUser] = useState([]);
  const [spotifyUser, setSpotifyUser] = useState([]);
  const {user} = useUser();

  useEffect(() => {
    async function fetchData() {
      try {
        // Fetching register user data
        const registerResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/register/${userEmail}`);
        if (!registerResponse.ok) {
          throw new Error('Failed to fetch register user');
        }
        const registerData = await registerResponse.json();
        setSpotifyUser(registerData);
        //console.log(registerData); // Debugging (remove if sensitive)
    
        // Fetching Spotify user data
        const spotifyResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/users/' + '\"' + registerData.userName + '\"', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
            'Content-Type': 'application/json',
          },
        });
    
        if (!spotifyResponse.ok) {
          throw new Error('Failed to fetch Spotify user');
        }
        const spotifyUserData = await spotifyResponse.json();
        // console.log(spotifyUserData)
        setBackendUser(spotifyUserData);
    
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle errors appropriately
      }
    }
  
    fetchData();
  },[]);  

  useEffect(() => {
    if (backendUser && backendUser.paidMember !== undefined) {
        setUserPro(backendUser.paidMember);
        //console.log(backendUser);
    }
}, [backendUser])

  function handleNavPic(){
    navigate("/Account")
  }
  function handleCardImgPress(){
    navigate("/Account")
}
  function handleProUser(){
    if(userPro === true){
      return(
        <>
        <img className="d-inline-block align-top" alt='' style={{width:'2.3rem',height:'2.3rem', marginRight:'.5rem', marginLeft:'16rem'}}src={crown}/>
        <img onClick={() => handleNavPic()} 
        src={backendUser?.profileImageUrl && backendUser.profileImageUrl.trim() !== '' ? backendUser.profileImageUrl : tasteMakersLogo}
        alt="Profile Image"
        onError={(e) => {
            e.target.onerror = null; // Prevents infinite callback loop
            e.target.src = tasteMakersLogo; // Fallback image if loading fails
        }}
        className="d-inline-block align-top" style={{width:'5rem',height:'5rem',borderRadius: "8rem",
                      borderColor:'orange', // Border color
                      borderWidth: '2px',    // Border width
                      borderStyle: 'solid',  // Border style
                      }}/>
        </>
      )
    }else if(userPro === false && userId != null){
      return(
        <img 
        src={backendUser?.profileImageUrl && backendUser.profileImageUrl.trim() !== '' ? backendUser.profileImageUrl : tasteMakersLogo}
        alt="Profile Image"
        onError={(e) => {
            e.target.onerror = null; // Prevents infinite callback loop
            e.target.src = tasteMakersLogo; // Fallback image if loading fails
        }}
        onClick={(e) => {
          e.stopPropagation();
          handleCardImgPress(backendUser.userId);
      }}
        className="d-inline-block align-top" style={{
          // borderColor:'orange', // Border color
          // borderWidth: '2px',    // Border width
          // borderStyle: 'solid',  // Border style
          width:'5rem',height:'5rem',borderRadius: "8rem", marginLeft:'19rem'}}/>
      )
    }else{
      return(
        <></>
      )
    }
  }

  function LogoutLogic(){
    if(backendUser.userId != null){
      return(
        <>
        {/* <Nav.Link href="/Create">Create</Nav.Link> */}
        {/* <Nav.Link href="/Account">Account</Nav.Link> */}
        <Nav.Link href="/Logout">Logout</Nav.Link>
        <Nav.Link href="/Upgrade" style={{
                  color:"#FFD700",
                  textShadow: "0 0 2px #fff, 0 0 0px #fff, 0 0 5px #fff, 0 0 50px #FFD700, 0 0 2px #FFD700, 0 0 0px #FFD700, 0 0 0px #FFD700, 0 0 0px #FFD700"}}>PRO</Nav.Link>
                  
        </>
        
      )
    }else if(userId == null){
      return(
      <Nav.Link href="/">Sign Up</Nav.Link>
      )
    }
  }

  return (
<div style={{ backgroundColor: "black" }}>
  <Navbar expand="sm"
    style={{
      backgroundSize: "0",
      backgroundColor: "#000000",
      top: "0",
      position: "fixed",
      zIndex: "999",
      width: "100%",
      position: "relative", // Ensure the pseudo-element is positioned correctly
    }}
    variant="dark">
    <Container>
      <img src={companyImg} className="img-circle" alt='...' style={{ width: '5rem', height: '5rem' }} />
      {/* <h1 style={{color:"WHITE", fontSize:"1.25rem", marginRight:"1rem", marginTop:"1vh"}}>BETA</h1> */}
      <Navbar.Brand href="/Feed" style={{
        fontFamily: 'Tenor Sans, sans-serif',
        fontSize: '2.5rem',
        background: `-webkit-linear-gradient(45deg, #ff4500, #ffa500)`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      }}>
        Muxelo
      </Navbar.Brand>
      <Navbar.Toggle
        style={{
          borderColor: "orange", // Outline of the hamburger menu
        }}
      >
        <span className="navbar-toggler-icon"
          style={{
            backgroundImage: "url('data:image/svg+xml,%3Csvg xmlns%3D%27http://www.w3.org/2000/svg%27 viewBox%3D%270 0 30 30%27%3E%3Cpath stroke%3D%27orange%27 stroke-linecap%3D%27round%27 stroke-miterlimit%3D%2710%27 stroke-width%3D%272%27 d%3D%27M4 7h22M4 15h22M4 23h22%27/%3E%3C/svg%3E')",
          }}
        />
      </Navbar.Toggle>
      <Navbar.Collapse id='basic-navbar-nav'>
        <Nav className="me-auto">
          <Nav.Link href="/Feed">Home</Nav.Link>
          {/* <Nav.Link href="/Competition">Competition</Nav.Link> */}
          <Nav.Link href="/Search">Search</Nav.Link>
          <Nav.Link href="/Account">Profile</Nav.Link>
          {LogoutLogic()}
        </Nav>
      </Navbar.Collapse>
      <NavbarCollapse>
        {handleProUser()}
        {/* <img className="d-inline-block align-top" style={{width:'2.3rem',height:'2.3rem', marginRight:'.5rem'}}src={crown}/> */}
        {/* <img src={userImg} className="d-inline-block align-top" alt='...' style={{width:'5rem',height:'5rem',borderRadius: "8rem"}}/> */}
      </NavbarCollapse>
    </Container>

    {/* Simulated Gradient Box Shadow */}
    <div style={{
      content: '""',
      position: 'absolute',
      bottom: '0',
      left: '0',
      width: '100%',
      height: '4px', // The thickness of the gradient line
      background: 'linear-gradient(90deg, yellow, orange, red)',
      zIndex: '1000', // Ensure it's above other elements
    }}></div>
  </Navbar>
</div>


  );
}

export default Navigation;